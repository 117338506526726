import React, { Fragment, memo } from 'react'

import { TableScroll, Button, onError, ObjectUtils } from '../../../modules';
import { translate, getLocaleKey } from '../../../languages';
import { TableFilterRangeTimeInput, TableFilterInputText } from '../../../components/table-filter-inputs';
import { AdminService } from '../../../services/admin';
import { withTransactionWraper } from '../wraper';
import { useSelector } from '../../../store';

export const WithdrawProgress = withTransactionWraper(memo(() => {
    const coinsState = useSelector(state => state.coins);
    const coinsOptions = ObjectUtils.getIn(coinsState, 'data', [], arr => arr.map((item: any) => ({ label: item.name, value: item.coinId })))

    return (
        <div className="WithdrawProgress">
            <TableScroll
                itemPerPages={20}
                filters={[
                    {
                        name: 'Time',
                        key: 'created',
                        input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />
                    },
                    {
                        name: 'Email',
                        key: 'email',
                        input: TableFilterInputText
                    }
                ]}
                structure={[
                    {
                        name: 'Coin',
                        key: 'coinId',
                        render: (item) => {
                            const coin = coinsOptions.find((v: any) => v.value === item.coinId);
                            return ObjectUtils.getIn(coin, 'label', '--');
                        },
                    },
                    {
                        name: translate('time'),
                        key: 'created',
                        render: (item) => {
                            const date = new Date(item.created);
                            return <span>
                                {date.toLocaleTimeString(getLocaleKey())} <br />
                                {date.toLocaleDateString(getLocaleKey())}
                            </span>
                        },
                    },
                    {
                        name: 'Email',
                        key: 'email',
                    },
                    {
                        name: 'Value',
                        key: 'value',
                        render: (item) => (+item.value).toLocaleString(getLocaleKey(), { maximumFractionDigits: 3 })
                    },
                    {
                        name: 'Actions',
                        render: (item, fetchData) => {
                            return <Fragment>
                                <Button
                                    className="mr5"
                                    type="button"
                                    buttonType="success-outline"
                                    label="Approve"
                                    onClick={async () => AdminService.withdrawApprove(item.transactionRequestId)
                                        .then(() => fetchData())
                                        .catch(onError)}
                                />

                                <Button
                                    type="button"
                                    buttonType="danger-outline"
                                    label="Reject"
                                    onClick={async () => AdminService.withdrawReject(item.transactionRequestId)
                                        .then(() => fetchData())
                                        .catch(onError)}
                                />
                            </Fragment>
                        }
                    },
                ]}
                fetchData={async state => {
                    let params = { ...state };
                    if (params['fromDate']) params['fromDate'] = new Date(params['fromDate']);
                    if (params['toDate']) params['toDate'] = new Date(params['toDate']);

                    return AdminService.getListWithdrawProgress({
                        page: params.pageNumber,
                        numberOfItemsPerPage: params.limit,
                        ...params
                    })
                }}
            />
        </div>
    )
}))