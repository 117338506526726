import React, { FC, useEffect, Fragment, ReactNode } from 'react'

import { useSelector } from '../../store';
import { Routes } from '../../AppRoutes';
import { useHistory } from 'react-router-dom';

export interface IPageProps {
    children: ReactNode
}

export const AuthWraper: FC<IPageProps> = (props) => {
    const { push } = useHistory();
    const auth = useSelector(state => state.user);

    useEffect(() => {
        if (auth) {
            push(localStorage.getItem('auth-redirect-to') || Routes.dashboard.path)
            localStorage.removeItem('auth-redirect-to')
        }
    }, [auth, push])

    return <Fragment>
        {props.children}
    </Fragment>
} 