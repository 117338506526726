import React, { FC, useState } from "react";
import * as Yup from "yup";

import { InputNumber } from "../input";
import {
  Button,
  useForm,
  InputWraper,
  CreateAlert,
  onError,
} from "../../modules";
import { AdminService } from "../../services/admin";

export const BudgetPercent: FC = () => {
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "budgetPercent",
        label: "Percent (%)",
        validate: Yup.number().required("Must be provided."),
      },
    ],
    onSubmit: async (values) => {
      return AdminService.budgetPercent(values.budgetPercent)
        .then(() => CreateAlert({ type: "success", message: "Successful!" }))
        .catch(onError);
    },
  });
  const [currentBudgetPercent, setCurrentBudgetPercent] = useState(0);

  AdminService.getBudgetPercent().then((newData) => {
    setCurrentBudgetPercent(newData);
  });

  return (
    <form onSubmit={handleSubmit} className="InterventionLimit">
      <div className="title">Budget Percent</div>
      <label>Current Budget Percent: {currentBudgetPercent}%</label>
      <InputWraper
        inputProps={getInputProps("budgetPercent")}
        component={InputNumber}
      />
      <Button isLoading={isSubmitting} type="submit" label="Submit" />
    </form>
  );
};
