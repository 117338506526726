import { RequestMainService } from "../request";
import { CookieService, ECookieVariable } from "../cookie";
import { ObjectUtils } from "../../modules";

export class AdminService {
  static async getListUser(params: any = {}) {
    return RequestMainService.get(`/admin/user`, params).then(({ result }) => ({
      count: result.total,
      data: result.users,
    }));
  }

  static getListUserStatus() {
    return [
      { label: "KYC_CONFIRMED", value: "KYC_CONFIRMED" },
      { label: "EMAIL_VERIFIED", value: "EMAIL_VERIFIED" },
      { label: "JUST_REGISTERED", value: "JUST_REGISTERED" },
    ];
  }

  // static async getUserDetail(email: string) {
  //     const userInfoRes = await RequestMainService.get(
  //         `/user/public-info/${email}`
  //     );

  //     return {
  //         userInfo: userInfoRes.result,
  //     };
  // }

  static async getUserDetail(userId: number) {
    return RequestMainService.get(`/admin/get-user?userId=${userId}`);
  }

  static async getListTransactions(params: any = {}) {
    return RequestMainService.get(`/admin/transaction`, params).then(
      ({ result }) => ({
        count: result.total,
        data: result.transactions,
      })
    );
  }

  static async getMarketInfo(coinId: any) {
    return RequestMainService.get(
      `/market-info/${CookieService.get(
        ECookieVariable.USER_SYMBOL_ACTIVE_ID
      )}/${coinId}`
    ).then(({ result }) => result);
  }

  static async getPendingKYC() {
    return RequestMainService.get(`/admin/kycs/pending`).then(({ result }) => ({
      count: result.length,
      data: result,
    }));
  }

  static async getListWithdrawProgress(params: any = {}) {
    return RequestMainService.get(`/admin/withdraw/history`, params).then(
      ({ result }) => {
        const data = result.withdrawProgresses.filter(
          (v: any) => v.status === "JUST_CREATED"
        );
        return {
          count: data.length,
          data: data,
        };
      }
    );
  }

  static async interventionLimit(amount: number) {
    return RequestMainService.post(`/game-setting/intervention-limit`, {
      interventionLimit: amount,
    });
  }

  static async budgetAmount(amount: number) {
    return RequestMainService.post(`/game-setting/budget-limit`, {
      budgetAmount: amount,
    });
  }

  static async currentMode(mode: string) {
    return RequestMainService.post(`/game-setting/current-mode`, {
      currentMode: mode,
    });
  }

  static async budgetPercent(budgetPercent: number) {
    return RequestMainService.post(`/game-setting/budget-percent`, {
      budgetPercent: budgetPercent,
    });
  }

  static async getBudgetAmount() {
    return RequestMainService.get(`/game-setting/budget-limit`).then(
      (res) => res.result
    );
  }

  static async getBudgetPercent() {
    return RequestMainService.get(`/game-setting/budget-percent`).then(
      (res) => res.result
    );
  }

  static async getAffiliation(numberOflevelsFromRoot: any, rootUserId: any) {
    return RequestMainService.get(
      `/tree/${numberOflevelsFromRoot}/${rootUserId}`
    ).then((res) => res.result);
  }

  static async getFranchiseUserReport(
    numberOflevelsFromRoot: any,
    rootUserId: any,
    params: any = {}
  ) {
    const offset = ObjectUtils.getIn(params, "offset", 0);
    const limit = ObjectUtils.getIn(params, "limit", 10);

    return RequestMainService.get(
      `/report-tree/${numberOflevelsFromRoot}/${rootUserId}`
    ).then((res) => {
      let data = [...res.result];
      if (typeof params.q === "string")
        data = data.filter((v) => {
          return (
            `${v.firstName} ${v.lastName}`
              .toLowerCase()
              .search(params.q.toLowerCase()) > -1 ||
            v.email.toLowerCase().search(params.q.toLowerCase()) > -1
          );
        });

      const paramKeys = [
        "wonVolume",
        "lostVolume",
        "rankCommission",
        "directCommission",
        "cashBack",
        "agencyCount",
      ];

      paramKeys.map((sortKey) => {
        if (params[sortKey]) {
          if (params[sortKey] === "increase")
            data = data.sort((a, b) => a[sortKey] - b[sortKey]);
          if (params[sortKey] === "descrease")
            data = data.sort((a, b) => b[sortKey] - a[sortKey]);
        }

        return sortKey;
      });

      return {
        data: data.slice(offset, offset + limit),
        count: data.length,
        full: data,
      };
    });
  }

  static async getFranchiseLeaderReport(
    numberOflevelsFromRoot: any,
    rootUserId: any,
    params: any = {}
  ) {
    const offset = ObjectUtils.getIn(params, "offset", 0);
    const limit = ObjectUtils.getIn(params, "limit", 10);

    return RequestMainService.get(
      `/leader-tree/${numberOflevelsFromRoot}/${rootUserId}`
    ).then((res) => {
      let data = [...res.result];
      if (typeof params.q === "string")
        data = data.filter((v) => {
          return (
            `${v.firstName} ${v.lastName}`
              .toLowerCase()
              .search(params.q.toLowerCase()) > -1 ||
            v.email.toLowerCase().search(params.q.toLowerCase()) > -1
          );
        });

      const paramKeys = [
        "wonVolume",
        "lostVolume",
        "rankCommission",
        "directCommission",
        "cashBack",
        "agencyCount",
      ];

      paramKeys.map((sortKey) => {
        if (params[sortKey]) {
          if (params[sortKey] === "increase")
            data = data.sort((a, b) => a[sortKey] - b[sortKey]);
          if (params[sortKey] === "descrease")
            data = data.sort((a, b) => b[sortKey] - a[sortKey]);
        }

        return sortKey;
      });

      return {
        data: data.slice(offset, offset + limit),
        count: data.length,
        full: data,
      };
    });
  }

  static async getFanshiseLeaderUserReport(userId: number, params: any = {}) {
    return RequestMainService.get(
      `/admin/branch-report/${userId}`,
      params
    ).then(({ result }) =>
      result.sort((a: any, b: any) => b.startAt - a.startAt)
    );
  }

  static async getSytemReport(params: any = {}) {
    return RequestMainService.get(`/admin/system-report`, params).then(
      (res) => res.result
    );
  }

  static async kycApprove(kycId: string) {
    return RequestMainService.post(`/admin/kyc/approve/${kycId}`);
  }

  static async kycReject(kycId: string) {
    return RequestMainService.post(`/admin/kyc/reject/${kycId}`);
  }

  static async withdrawApprove(withdrawId: string) {
    return RequestMainService.post(`/admin/withdraw/accept/${withdrawId}`);
  }

  static async withdrawReject(withdrawId: string) {
    return RequestMainService.post(`/admin/withdraw/reject/${withdrawId}`);
  }

  static async setRank(userId: number, rank: number) {
    return RequestMainService.post(`/privilege`, { userId, rank });
  }

  static async freezeUsers(userIds: number[]) {
    return RequestMainService.post(`/admin/freeze-user`, { userIds });
  }

  static async unfreezeUsers(userIds: number[]) {
    return RequestMainService.post(`/admin/unfreeze-user`, { userIds });
  }

  static async freezeUsersTransfer(userIds: number[]) {
    return RequestMainService.post(`/admin/freeze-user-transfer`, {
      userIds,
    });
  }

  static async unfreezeUsersTransfer(userIds: number[]) {
    return RequestMainService.post(`/admin/unfreeze-user-transfer`, {
      userIds,
    });
  }

  static async freezeUsersWithdraw(userIds: number[]) {
    return RequestMainService.post(`/admin/freeze-user-withdraw`, {
      userIds,
    });
  }

  static async unfreezeUsersWithdraw(userIds: number[]) {
    return RequestMainService.post(`/admin/unfreeze-user-withdraw`, {
      userIds,
    });
  }

  static async updateProfile(data: any) {
    return RequestMainService.put(`/admin/profile-user`, { ...data });
  }
}
