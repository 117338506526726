import React, { FC } from "react";
import "./index.scss";
import {
    Button,
    ObjectUtils,
    useForm,
    InputWraper,
    CreateAlert,
} from "../../../../modules";
import { translate } from "../../../../languages";
import * as Yup from "yup";
import { InputText } from "../../../../components";
import { AdminService } from "../../../../services/admin";

export const EditUser: FC<{ data: any; onFinishEdit: () => void }> = (
    props
) => {
    const { email, firstName, lastName, phoneNumber } = props.data;
    const { handleSubmit, getInputProps, isSubmitting } = useForm({
        structure: [
            {
                name: "email",
                defaultValue: email,
                validate: Yup.string().required(translate("must-be-provided")),
                isDisabled: true,
            },
            {
                name: "firstName",
                defaultValue: firstName,
                validate: Yup.string().required(translate("must-be-provided")),
            },
            {
                name: "lastName",
                defaultValue: lastName,
                validate: Yup.string().required(translate("must-be-provided")),
            },
            {
                name: "phoneNumber",
                defaultValue: phoneNumber || "",
                validate: Yup.string().required(translate("must-be-provided")),
            },
        ],
        // onSubmit: async (values) => {
        //     // console.log(values);
        //     return AdminService.updateProfile({ ...values, phoneCountryId: 1 })
        //         .then(() => {
        //             CreateAlert({
        //                 message: "Please check email to verify your account.",
        //                 type: "success",
        //             });
        //             props.onFinishEdit();
        //         })
        //         .catch((error) => {
        //             CreateAlert({
        //                 message: error.message,
        //                 type: "danger",
        //             });
        //         });
        // },
        onSubmit: async (values) => {
            console.log(values);
            const { userId, countryId: phoneCountryId } = props.data;
            return AdminService.updateProfile({
                ...values,
                userId,
                phoneCountryId,
            })
                .then(() => {
                    CreateAlert({
                        message: "Update success.",
                        type: "success",
                    });
                    props.onFinishEdit();
                })
                .catch((error) => {
                    CreateAlert({
                        message: error.message,
                        type: "danger",
                    });
                });
        },
    });
    return (
        <form className="Edit" onSubmit={handleSubmit}>
            <InputWraper
                label={translate("email-address")}
                inputProps={getInputProps("email")}
                component={InputText}
            />
            <div className="group">
                <InputWraper
                    label={translate("first-name")}
                    inputProps={getInputProps("firstName")}
                    component={InputText}
                    className="mr5"
                />
                <InputWraper
                    label={translate("last-name")}
                    inputProps={getInputProps("lastName")}
                    component={InputText}
                    className="ml5"
                />
            </div>

            <InputWraper
                label={translate("phone-number")}
                inputProps={getInputProps("phoneNumber")}
                component={InputText}
            />
            <div>
                <Button
                    // className="mb20"
                    label="Update"
                    // onClick={props.onClick}
                    isMiddle
                    // buttonType="warning"
                    className="btnBan"
                    type="submit"
                />
            </div>
        </form>
    );
};
