import React, { FC, useState } from 'react'

import { getLocaleKey } from '../../languages';
import { useTradeCoinWithBalance } from '../../hook';
import { EOrderOption } from '../../types';

export let SetOpenedOrders = (data: any[]): any => data;

export const Sentiments: FC = () => {
    const { coinActive } = useTradeCoinWithBalance();
    const [openedOrders, setOpenedOrders] = useState([] as any[]);

    SetOpenedOrders = (data: any[]) => setOpenedOrders(data);

    const higherOrders: any[] = openedOrders.filter((item: any) => item.coinId === coinActive.coinId && item.option === EOrderOption.HIGHER);
    const higherOrderTotal: number = higherOrders.length;
    const higherOrderTotalAmount: number = higherOrders.reduce((output, item) => output + item.amount, 0);

    const lowerOrders: any[] = openedOrders.filter((item: any) => item.coinId === coinActive.coinId && item.option === EOrderOption.LOWER);
    const lowerOrderTotal: number = lowerOrders.length;
    const lowerOrderTotalAmount: number = lowerOrders.reduce((output, item) => output + item.amount, 0);

    const getSentiments = () => {
        if (higherOrderTotal === lowerOrderTotal) return {
            higherOrder: 50,
            lowerOrder: 50,
        }

        const higherOrder: number = (higherOrderTotal / (lowerOrderTotal + higherOrderTotal)) * 100
        const lowerOrder: number = 100 - higherOrder;

        return {
            higherOrder,
            lowerOrder,
        }
    }

    const sentiments = getSentiments()

    return (
        <div className="Sentiments">
            <div className="sentiments">
                <div className="percent">
                    <div className="down" style={{ width: `${sentiments.lowerOrder}%` }}></div>
                    <div className="up" style={{ width: `${sentiments.higherOrder}%` }}></div>
                </div>

                <div className="percentLabel">
                    <div className="down">{sentiments.lowerOrder.toLocaleString(getLocaleKey(), { maximumFractionDigits: 1 })}% / ${lowerOrderTotalAmount.toLocaleString(getLocaleKey(), { maximumFractionDigits: 3 })}</div>
                    <div className="up">{sentiments.higherOrder.toLocaleString(getLocaleKey(), { maximumFractionDigits: 1 })}% / ${higherOrderTotalAmount.toLocaleString(getLocaleKey(), { maximumFractionDigits: 3 })}</div>
                </div>
            </div>
        </div>
    )
}