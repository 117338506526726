import React, { FC } from 'react'
import NumberFormat from 'react-number-format';
import { IInputProps } from '../../../modules';

export const InputNumber: FC<IInputProps> = ({ value, onChange, onTouched }) => {
    return (
        <NumberFormat
            fixedDecimalScale={false}
            value={typeof value === 'number' ? value : undefined}
            onValueChange={({ floatValue }) => {
                const data = floatValue || 0;
                onChange(data);
            }}
            onBlur={() => setTimeout(() => onTouched(), 500)}
        />
    )
}