import { combineReducers } from 'redux';

import { mainReducer } from '../services/main/main.reducer';
import { tradeHistoriesReducer } from '../services/trade/tradeHistories.reducer';
import { symbolsReducer } from '../services/trade/symbols.reducer';
import { countriesReducer } from '../services/main/contries.reducer';
import { coinConversionRateReducer } from '../services/bank/coinConversionRate.reducer';
import { tradeLastResultReducer } from '../services/trade/tradeLastResult.reducer';
import { resultPannelDropletsReducer } from '../services/trade/resultPannelDroplets.reducer';
import { coinsReducer } from '../services/trade/coins.reducer';
import { userReducer } from '../services/user/user.reducer';
import { userExternalWalletsReducer } from '../services/user/userExternalWallets.reducer';
import { userInternalWalletsReducer } from '../services/user/userInternalWallets.reducer';
import { userWalletBalancesReducer } from '../services/user/userWalletBalances.reducer';
import { openedOrdersReducer } from '../services/order/openedOrders.reducer';

export const reducers = combineReducers({
    main: mainReducer,
    
    user: userReducer,
    userExternalWallets: userExternalWalletsReducer,
    userInternalWallets: userInternalWalletsReducer,
    userWalletBalances: userWalletBalancesReducer,

    countries: countriesReducer,

    symbols: symbolsReducer,
    coins: coinsReducer,
    coinConversionRate: coinConversionRateReducer,
    
    tradeHistories: tradeHistoriesReducer,
    tradeLastResult: tradeLastResultReducer,
    
    resultPannelDroplets: resultPannelDropletsReducer,
    
    openedOrders: openedOrdersReducer,
});