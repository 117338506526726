import React, { FC } from "react";
import * as Yup from "yup";

import { Button, useForm, CreateAlert, onError } from "../../modules";
import { AdminService } from "../../services/admin";
import { Icon } from "..";
import Select from "react-select";
import { useState } from "react";
import { isEqual, isNil } from "lodash";

export const ChangeMode: FC = () => {
  const [currentMode, setCurrentMode] = useState("FREE");
  const [currentBudgetAmount, setCurrentBudgetAmount] = useState(0);
  const currentSettingMode = localStorage.getItem("settingMode");
  AdminService.getBudgetAmount().then((newData) => {
    setCurrentBudgetAmount(newData);
  });

  const modes = [
    {
      label: "INTERVENTION",
      value: "INTERVENTION",
    },
    {
      label: "FREE",
      value: "FREE",
    },
    {
      label: "BUDGET",
      value: "BUDGET",
    },
  ];

  const handleSubmit = async () => {
    switch (currentMode) {
      case "FREE":
        localStorage.setItem("settingMode", "FREE");
        await AdminService.currentMode("FREE")
          .then(() => {
            CreateAlert({
              type: "success",
              message: "Set mode FREE successful!",
            });
          })
          .catch(onError);
        break;
      case "INTERVENTION":
        localStorage.setItem("settingMode", "INTERVENTION");
        await AdminService.currentMode("INTERVENTION")
          .then(() => {
            CreateAlert({
              type: "success",
              message: "Set mode INTERVENTION successful!",
            });
          })
          .catch(onError);
        break;
      case "BUDGET":
        localStorage.setItem("settingMode", "BUDGET");
        await AdminService.currentMode("BUDGET")
          .then(() => {
            CreateAlert({
              type: "success",
              message: "Set mode BUDGET successful!",
            });
          })
          .catch(onError);
        break;
      default:
        break;
    }
  };

  const getValues = () => {
    const mode = localStorage.getItem("settingMode");
    return modes.find((v: any) => isEqual(mode, v.value));
  };

  return (
    <form className="InterventionLimit">
      <div className="title">Setting Mode</div>
      <label>Current Setting Mode: {currentSettingMode}</label>
      <div className="mt15">
        <Select
          options={modes}
          className="TableFilterInputSelect"
          classNamePrefix="TableFilterInputSelect"
          placeholder="-- Select --"
          defaultValue={
            localStorage.getItem("settingMode") != ""
              ? modes.find((v) => v.value === "FREE")
              : modes.find(
                  (v) => v.value === localStorage.getItem("settingMode")
                )
          }
          onChange={(value) => {
            let currentValue = value as { label: string; value: string };
            if (!isNil(currentValue)) {
              setCurrentMode(currentValue.value);
            }
          }}
        />
      </div>
      <Button
        onClick={handleSubmit}
        className="mt30"
        type="button"
        label="Submit"
      />
    </form>
  );
};
