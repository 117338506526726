import React, { useState, useEffect } from 'react'

import { AdminService } from '../../../services/admin';
import { useSelector } from '../../../store';
import { TreeView } from '../../../components/tree-view';
import { Message } from '../../../modules';
import { withFranchiseWraper } from '../wraper';

export const PageFranchiseTree = withFranchiseWraper(() => {
    const [affiliation, setAffiliation] = useState(null as any);
    const user = useSelector(state => state.user);

    useEffect(() => {
        AdminService.getAffiliation(21, user.userId)
            .then(res => setAffiliation({ data: res }))
            .catch(err => setAffiliation({ error: err.error }));
        // eslint-disable-next-line
    }, [])
 
    return (
        <div className="PageFranchiseTree">
            {(() => {
                if (!affiliation) return <Message type="loading" />
                if (affiliation.error) return <Message type="error" message={affiliation.error.message} />
                return <TreeView affiliate={affiliation.data} />
            })()}
        </div>
    )
})