import React, { FC } from 'react'
import { RouteComponentProps, NavLink } from 'react-router-dom'
import { Routes } from '../../AppRoutes'
import { Icon } from '../../components'

export interface IUserWraperProps extends RouteComponentProps {

}

export const withUserWraper = (Component: FC<IUserWraperProps>) => (props: IUserWraperProps) => {
    return (
        <div className="withUserWraper">
            <div className="TabLinks">
                <NavLink to={Routes.userList.path} exact={true}>
                    <Icon.User />

                    User List
                </NavLink>

                <NavLink to={Routes.userKYCList.path} exact={true}>
                    <Icon.KYC />

                    KYC List
                </NavLink>
            </div>

            <Component {...props} />
        </div>
    )
}