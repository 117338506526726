import React, { FC } from "react";
import { BudgetAmount } from "../../components/budget-amount";
import { BudgetPercent } from "../../components/budget-percent";
import { ChangeMode } from "../../components/change-mode";

import { ControlTradeResults } from "../../components/control-trade-results";
import { InterventionLimit } from "../../components/intervention-limit";
import { OpennedOrders } from "../../components/opened-orders";
import { TradePannel } from "./trade-pannel";

export const PageDashboard: FC = () => {
  return (
    <div className="PageDashboard">
      <div className="row mb20">
        <div className="col-sm-5">
          <TradePannel />
        </div>

        <div className="col-sm-3">
          <ControlTradeResults />
        </div>

        <div className="col-sm-4">
          <InterventionLimit />
        </div>
      </div>
      <div className="row mb20">
        <div className="col-sm-4">
          <BudgetAmount />
        </div>

        <div className="col-sm-4">
          <BudgetPercent />
        </div>
        <div className="col-sm-4">
          <ChangeMode/>
        </div>
      </div>

      <OpennedOrders />
    </div>
  );
};
