import React, { FC, useState } from "react";
import * as Yup from "yup";

import { InputNumber } from "../input";
import {
  Button,
  useForm,
  InputWraper,
  CreateAlert,
  onError,
} from "../../modules";
import { AdminService } from "../../services/admin";

export const BudgetAmount: FC = () => {
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "budgetAmount",
        label: "Amount",
        validate: Yup.number().required("Must be provided."),
      },
    ],
    onSubmit: async (values) => {
      return AdminService.budgetAmount(values.budgetAmount)
        .then(() => CreateAlert({ type: "success", message: "Successful!" }))
        .catch(onError);
    },
  });

  const [currentBudgetAmount, setCurrentBudgetAmount] = useState(0);

  AdminService.getBudgetAmount().then((newData) => {
    setCurrentBudgetAmount(newData);
  });

  return (
    <form onSubmit={handleSubmit} className="InterventionLimit">
      <div className="title">Budget Amount</div>
      <label>Current Budget Amount: ${currentBudgetAmount}</label>
      <InputWraper
        inputProps={getInputProps("budgetAmount")}
        component={InputNumber}
      />
      <Button isLoading={isSubmitting} type="submit" label="Submit" />
    </form>
  );
};
