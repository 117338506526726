import React, { Fragment, memo } from 'react'
import { withUserWraper } from '../wraper'
import { Table, Button, onError } from '../../../modules'
import { AdminService } from '../../../services/admin'
import { onImageGallery } from '../../../components/image-gallery'

export const PageUserKYCList = withUserWraper(memo(() => {
    return <Fragment>
        <Table 
            structure={[
                {
                    name: 'Email',
                    key: 'email',
                },
                {
                    name: "Username",
                    key: "username",
                },
                {
                    name: 'First Name',
                    key: 'firstName'
                },
                {
                    name: 'Last Name',
                    key: 'lastName'
                },
                {
                    name: 'Avatar',
                    render: (item) => {
                        const dataImage = [{ src: item.avartarUrl }, { src: item.backOfCardUrl }, { src: item.frontOfCardUrl }].map(v => ({ ...v, thumbnail: v.src }));
                        return (
                            <img onClick={() => onImageGallery(dataImage, 0)} src={item.avartarUrl} style={{ cursor: 'pointer', height: '100px', width: '200px', objectFit: 'contain' }} alt="" />
                        )
                    }
                },
                {
                    name: 'Back of card',
                    render: (item) => {
                        const dataImage = [{ src: item.avartarUrl }, { src: item.backOfCardUrl }, { src: item.frontOfCardUrl }].map(v => ({ ...v, thumbnail: v.src }));
                        return (
                            <img onClick={() => onImageGallery(dataImage, 1)} src={item.backOfCardUrl} style={{ cursor: 'pointer', height: '100px', width: '200px', objectFit: 'contain' }} alt="" />
                        )
                    }
                },
                {
                    name: 'Front of card',
                    render: (item) => {
                        const dataImage = [{ src: item.avartarUrl }, { src: item.backOfCardUrl }, { src: item.frontOfCardUrl }].map(v => ({ ...v, thumbnail: v.src }));
                        return (
                            <img onClick={() => onImageGallery(dataImage, 2)} src={item.frontOfCardUrl} style={{ cursor: 'pointer', height: '100px', width: '200px', objectFit: 'contain' }} alt="" />
                        )
                    }
                },
                {
                    name: 'Actions',
                    render: (item, fetchData) => {
                        return <Fragment>
                            <Button
                                className="mr5"
                                type="button"
                                buttonType="success-outline"
                                label="Approve"
                                onClick={async () => AdminService.kycApprove(item.kycId)
                                    .then(() => fetchData())
                                    .catch(onError)}
                            />

                            <Button
                                type="button"
                                buttonType="danger-outline"
                                label="Reject"
                                onClick={async () => AdminService.kycReject(item.kycId)
                                    .then(() => fetchData())
                                    .catch(onError)}
                            />
                        </Fragment>
                    }
                },
            ]}
            fetchData={async () => AdminService.getPendingKYC()}
        />
    </Fragment>
}))