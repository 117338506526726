import { RequestMainService } from "../request";
import { Store } from "redux";
import { SET_COIN_CONVERSION_RATE } from "./coinConversionRate.reducer";

export interface IRequestWithdrawPayload {
    coinId: number
    feeCoinId: number
    value: number
}

export class BankService {
    static async getTransactions(params: any = {}) {
        return RequestMainService.get(`/bank/transaction`, params)
            .then(({ result }) => ({
                count: result.total,
                data: result.transactions
            }))
    }

    static async getCoinConversionRate(store: Store) {
        return RequestMainService.get(`/bank/rates-to-usd`)
            .then(({ result }) => store.dispatch({ type: SET_COIN_CONVERSION_RATE, data: result }))
            .catch(res => store.dispatch({ type: SET_COIN_CONVERSION_RATE, error: res.error }))
    }

    static getTransactionTypeOptions() {
        return [
            { label: "INIT", value: 0 },
            { label: "DEPOSIT", value: 1 },
            { label: "WITHDRAW", value: 2 },
            { label: "RECEIVE_TRANSFER", value: 3 },
            { label: "SEND_TRANSFER", value: 4 },
            { label: "DEPOSIT_FEE", value: 5 },
            { label: "WITHDRAW_FEE", value: 6 },
            { label: "SEND_TRANSFER_FEE", value: 7 },
            { label: "RECEIVE_TRANSFER_FEE", value: 8 },
            { label: "TRADE_FEE", value: 9 },
            { label: "OTHER_INCOME", value: 10 },
            { label: "OTHER_FEE", value: 11 },
            { label: "BUY_PRESALE_COIN", value: 12 },
            { label: "REFUND_WITHDRAW_FEE", value: 13 },
            { label: "DIRECT_COMMISSION", value: 14 },
            { label: "BUY_AGENCY_LICENSE", value: 15 },
            { label: "UPGRADE_MINER", value: 16 },
            { label: "BANK_RECEIVE_FROM_USER", value: 17 },
            { label: "BANK_SEND_TO_USER", value: 18 },
            { label: "SEND_EXCHANGE_SOURCE_COIN", value: 19 },
            { label: "RECEIVE_EXCHANGE_DESTINATION_COIN", value: 20 },
            { label: "SEND_EXCHANGE_FEE", value: 21 },
            { label: "BUY_MINER_COMMISSION", value: 22 },
            { label: "UPGRADE_MINER_COMMISSION", value: 23 },
            { label: "SYSTEM_COMMISSION", value: 24 },
            { label: "CASH_BACK", value: 25 },
            { label: "RANK_COMMISSION", value: 26 },
            { label: "REFUND_WITHDRAW", value: 27 },
            { label: "REFILL_DEMO_ACCOUNT", value: 28 },
            { label: "RETURN_MINER_FEE", value: 29 },
            { label: "PLACE_BET", value: 46 },
            { label: "BET_PROFIT", value: 47 },
        ]
    }
}