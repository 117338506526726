import React from 'react'

import { ObjectUtils, Table } from '../../../modules'
import { AdminService } from '../../../services/admin'
import { translate, getLocaleKey } from '../../../languages'
import { TableFilterInputText, TableFilterRangeTimeInput } from '../../../components/table-filter-inputs'
import { TableFilterInputSelect } from '../../../components/table-filter-inputs/select'
import { BankService } from '../../../services'
import { TableFilterInputNumber } from '../../../components/table-filter-inputs/number'
import { useSelector } from '../../../store'
import { withTransactionWraper } from '../wraper'

export const TransactionDemoList = withTransactionWraper(() => {
    const last1MothDate = Date.now() - 1000 * 60 * 60 * 24 * 30;
    const coinsState = useSelector(state => state.coins);
    const coinsOptions = ObjectUtils.getIn(coinsState, 'data', [], arr => arr.map((item: any) => ({ label: item.name, value: item.coinId })))

    return (
        <div className="TransactionDemoList">
            <Table
                itemPerPages={10}
                filters={[
                    {
                        name: translate('time'),
                        key: 'created',
                        input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
                        defaultValue: {
                            fromDate: new Date(last1MothDate),
                            toDate: new Date(),
                        }
                    },
                    {
                        name: 'Email',
                        key: 'email',
                        input: TableFilterInputText
                    },
                    {
                        name: 'Amount',
                        key: 'amount',
                        input: TableFilterInputNumber
                    },
                    {
                        name: translate('type'),
                        key: 'transactionTypeId',
                        input: (s) => <TableFilterInputSelect
                            {...s}
                            options={BankService.getTransactionTypeOptions()}
                        />
                    }
                ]}
                structure={[
                    {
                        name: 'Coin',
                        key: 'coinId',
                        render: (item) => {
                            const coin = coinsOptions.find((v: any) => v.value === item.coinId);
                            return ObjectUtils.getIn(coin, 'label', '--');
                        },
                    }, 
                    {
                        name: translate('time'),
                        key: 'created',
                        render: (item) => {
                            const date = new Date(item.created);
                            return <span>
                                {date.toLocaleTimeString(getLocaleKey())} <br />
                                {date.toLocaleDateString(getLocaleKey())}
                            </span>
                        },
                    }, 
                    {
                        name: 'Email',
                        key: 'email',
                    },
                    {
                        name: translate('amount'),
                        key: 'value',
                        render: (item) => {
                            const { balanceAfter, balanceBefore } = item;
                            let isIncrease = false;
                            if (balanceAfter > balanceBefore) isIncrease = true;
                            return <span className={isIncrease ? 'textSuccess' : 'textDanger'}>
                                {isIncrease ? '+' : '-'}{(+item.value).toLocaleString(getLocaleKey(), { maximumFractionDigits: 3 })}
                            </span>
                        },
                    },
                    {
                        name: translate('type'),
                        key: 'transactionTypeId',
                        render: (item) => ObjectUtils.getIn(item, 'transactionType.name', '--'),
                    },
                    {
                        name: translate('description'),
                        key: 'description',
                    },
                    {
                        name: 'Txhash',
                        key: 'description',
                        render: (item) => {
                            const hash = ObjectUtils.getIn(item, 'transactionHash');
                            const coin = coinsOptions.find((v: any) => v.value === item.coinId);
                            if (hash && coin.value === 4) return <a href={`https://etherscan.io/tx/${hash}`} target="__blank">
                                Click me
                            </a>
                            if (hash && coin.value === 6) return <a href={`https://tronscan.io/#/transaction/${hash}`} target="__blank">
                                Click me
                            </a>

                            return '--'
                        }
                    },
                ]}
                fetchData={async state => {
                    let params = { ...state };
                    if (params['fromDate']) params['fromDate'] = new Date(params['fromDate']);
                    if (params['toDate']) params['toDate'] = new Date(params['toDate']);
   
                    return AdminService.getListTransactions({
                        page: params.pageNumber,
                        numberOfTransactionsPerPage: params.limit,
                        ...params,
                        coinId: 5,
                    })
                }}
            />
        </div>
    )
})