import socketIOClient from 'socket.io-client';
import { getEnv } from '../../configs';
import { CookieService, ECookieVariable } from '../cookie';
import { TradeService } from '../trade';
import { store } from '../../store';

export class SocketService {
    static socket: SocketIOClient.Socket

    static COMMAND_TYPE = {
        REFRESH_BALANCE: 'REFRESH_BALANCE',
        NEW_TRANSACTION: 'NEW_TRANSACTION',
        NEW_MARKET_INFO: 'NEW_MARKET_INFO',
        UPDATE_ACTIVE_ORDER: 'UPDATE_ACTIVE_ORDER',
        ORDER_RESULT: 'ORDER_RESULT',
    }

    static EMIT_TYPE = {
        SUBSCRIBE: 'SUBSCRIBE_SUB_ROOM',
        UNSUBSCRIBE: 'UNSUBSCRIBE_SUB_ROOM'
    }

    static MARKET_NAME = {
        UNIC_ETH: 'UNIC/ETH'
    }

    static NEW_TRANSACTION_TYPE = {
        MATCH_ORDER: 'MATCH_ORDER',
        DEPOSIT: 'DEPOSIT',
        WITHDRAW: 'WITHDRAW',
        RECEIVE_TRANSFER: 'RECEIVE_TRANSFER',
        BUY_MINER_COMMISSION: 'BUY_MINER_COMMISSION',
        SYSTEM_COMMISSION: 'SYSTEM_COMMISSION',
        MINER_INCOME: 'MINER_INCOME',
        RANK_COMMISSION: 'RANK_COMMISSION',
        REFUND_WITHDRAW: 'REFUND_WITHDRAW',
        UPGRADE_MINER_COMMISSION: 'UPGRADE_MINER_COMMISSION',
        PURCHASE_SHARED_PACKAGE_COMMISSION: 'PURCHASE_SHARED_PACKAGE_COMMISSION'
    }

    static disconnect() {
        if (!this.socket) return;
        const symbolId = TradeService.getSymbolIdFromSymbolName(decodeURIComponent(CookieService.get(ECookieVariable.USER_SYMBOL_ACTIVE_NAME)), store);
        const coinId = TradeService.getCoinIdFromCoinCode(CookieService.get(ECookieVariable.USER_TRADE_COIN), store)
        this.socket.emit(this.EMIT_TYPE.UNSUBSCRIBE, `MARKET_INFO_SYMBOL_ID=${symbolId}_COIN_ID=${coinId}`)
        this.socket.disconnect();
        // @ts-ignore
        this.socket = undefined;
    }

    static on(inCommand: string, callBack: (payload: any) => void) {
        return this.initialize().on('NEW_SERVER_COMMAND', (command: any, payload: any) => {
            // console.log(command, payload);
            if (inCommand === command) callBack(payload);
        });
    }

    static initialize(forceNew?: boolean): SocketIOClient.Socket {
        if (!this.socket || forceNew) {
            const socket = socketIOClient(`${getEnv('URL_SOCKET')}?token=${CookieService.get(ECookieVariable.USER_ACCESS_TOKEN)}&appId=${getEnv('URL_SOCKET_APP_ID')}`, { transports: ['websocket'] });
            this.socket = socket;

            const symbolId = TradeService.getSymbolIdFromSymbolName(decodeURIComponent(CookieService.get(ECookieVariable.USER_SYMBOL_ACTIVE_NAME)), store);
            const coinId = TradeService.getCoinIdFromCoinCode(CookieService.get(ECookieVariable.USER_TRADE_COIN), store)

            socket.emit(this.EMIT_TYPE.SUBSCRIBE, `MARKET_INFO_SYMBOL_ID=${symbolId}_COIN_ID=${coinId}`)

            return socket
        }

        return this.socket
    }

    static removeListenerCommand() {
        if (this.socket) {
            return this.socket.removeAllListeners();
        }
    }
}