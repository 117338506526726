export enum ELocale {
    ENGLISH = 'en-US',
    VIETNAM = 'vi-VN',
    JAPAN = 'ja-JP',
    THAILAN = 'th-TH',
    INDONESIA = 'id-ID',
    MALAYSIA = 'ms-MY',
    CHINA = 'zh-CN',
}

export interface IFirebaseConfig {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
}

export enum EDeviceType {
    DESKTOP = 'Desktop',
    MOBILE = 'Mobile',
}

export enum EOrderStatus {
    OPEN = 'OPEN',
    WIN = 'WIN',
    LOSE = 'LOSE',
    DRAW = 'DRAW',
}

export enum EOrderOption {
    HIGHER = 'HIGHER',
    LOWER = 'LOWER',
}

export interface IOrder {
    orderId: number
    userId: number
    symbolId: number
    coinId: number
    amount: number
    profit: number
    status: EOrderStatus
    option: EOrderOption
    created: Date
    modified: Date
}