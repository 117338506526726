import React, { FC } from 'react'
import * as Yup from 'yup';

import { InputNumber } from '../input'
import { Button, useForm, InputWraper, CreateAlert, onError } from '../../modules';
import { AdminService } from '../../services/admin';

export const InterventionLimit: FC = () => {
    const { handleSubmit, getInputProps, isSubmitting } = useForm({
        structure: [
            {
                name: 'interventionLimit',
                label: 'Amount',
                validate: Yup.number().required('Must be provided.'),
            }
        ],
        onSubmit: async (values) => {
            return AdminService.interventionLimit(values.interventionLimit)
                .then(() => CreateAlert({ type: 'success', message: 'Successful!' }))
                .catch(onError)
        }
    })

    return (
        <form onSubmit={handleSubmit} className="InterventionLimit">
            <div className="title">Intervention limit</div>
            <InputWraper
                inputProps={getInputProps('interventionLimit')}
                component={InputNumber}
            />
            <Button isLoading={isSubmitting} type="submit" label="Submit" />
        </form>
    )
}