import React, { useState, useEffect, FC, memo } from "react";
import * as XLSX from "xlsx";

import { withReportWraper } from "../wraper";
import { AdminService } from "../../../services/admin";
import { InputDateTimeRange } from "../../../components/table-filter-inputs";
import {
  Message,
  Table,
  Button,
  ObjectUtils,
  CreateAlert,
  ITableStructureItem,
} from "../../../modules";
import { getLocaleKey } from "../../../languages";
import { Icon, InputSelect } from "../../../components";

export const ReportSystem = withReportWraper(
  memo(
    () => {
      const [rangeTime, setRangeTime] = useState({
        startTime: Date.now() - 1000 * 60 * 60 * 24 * 30,
        endTime: Date.now(),
      } as any);

      const [reports, setReports] = useState(null as any);
      const [systemReportDetail, setSystemReportDetail] = useState(null as any);
      const [modeOption, setModeOption] = useState("");

      useEffect(() => {
        if (!rangeTime) {
          setReports(null);
        } else {
          setReports(null);

          AdminService.getSytemReport({
            fromDate: new Date(rangeTime.startTime),
            toDate: new Date(rangeTime.endTime),
          })
            .then((res) => {
              const sumReports = Object.keys(res[0]).reduce(
                (ouput: any, key: string) => {
                  if (typeof res[0][key] === "number")
                    ouput[key] = res.reduce(
                      (sum: number, item: any) => sum + item[key],
                      0
                    );
                  return ouput;
                },
                {}
              );

              setReports({
                count: res.length,
                data: res.sort((a: any, b: any) => b.start - a.start),
                sumReports,
              });
            })
            .catch((err) => setReports({ error: err.error }));
        }
      }, [rangeTime]);

      const ModeOptions = [
        { label: "Balance", value: "userBalance" },
        { label: "Hot Wallet", value: "hotWallet" },
        { label: "Withdraw", value: "withdraw" },
        { label: "Deposit", value: "deposit" },
        { label: "Safe", value: "safe" },
      ];

      const sumMiddleware =
        (func: (item: any) => any) =>
        (item: any, fetchData: any, column: ITableStructureItem) => {
          if (item.isSum) {
            if (column.key === "date") return "SUM";
            if (
              column.key &&
              reports &&
              reports.sumReports &&
              typeof reports.sumReports[column.key] === "number"
            ) {
              if (Number.isNaN(reports.sumReports[column.key])) return "--";
              const value = (
                +reports.sumReports[column.key] || 0
              ).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2 });
              if (column.key === "lostVolume") return `-$${value}`;
              return `$${value}`;
            }

            return "--";
          }

          return func(item);
        };

      const structure = [
        {
          name: "Date",
          key: "date",
          render: sumMiddleware((item) => {
            return (
              <div>
                {new Date(item.end).toLocaleDateString(getLocaleKey(), {
                  hour12: false,
                })}
              </div>
            );
          }),
          sort: {
            key: "sort-date",
          },
        },
        {
          name: "Won volume",
          key: "wonVolume",
          // className: 'textSuccess',
          render: sumMiddleware((item: any) => {
            return `$${(+item.wonVolume).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 2,
            })}`;
          }),
          sort: {
            key: "sort-won-volume",
          },
        },
        {
          name: "Lose volume",
          // className: 'textDanger',
          key: "lostVolume",
          render: sumMiddleware((item: any) => {
            const value = +item.lostVolume;
            return value
              ? `-$${(+item.lostVolume).toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 2,
                })}`
              : 0;
          }),
          sort: {
            key: "sort-lost-volume",
          },
        },
        {
          name: "Hot Wallet Usdt",
          key: "hotWalletUsdt",
          render: sumMiddleware((item: any) => {
            return `$${(+item.hotWalletUsdt).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 2,
            })}`;
          }),
          sort: {
            key: "hotWalletUsdt",
          },
        },
        {
          name: "Hot Wallet Usdt TRC20",
          key: "hotWalletUsdtTrc20",
          render: sumMiddleware((item: any) => {
            return `$${(+item.hotWalletUsdtTrc20).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 2,
            })}`;
          }),
          sort: {
            key: "hotWalletUsdt",
          },
        },
        {
          name: "Deposit Usdt",
          key: "depositUsdt",
          render: sumMiddleware((item: any) => {
            return `$${(+item.depositUsdt).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 2,
            })}`;
          }),
          sort: {
            key: "depositUsdt",
          },
        },
        {
          name: "Deposit Usdt Trc20",
          key: "depositUsdtTrc20",
          render: sumMiddleware((item: any) => {
            return `$${(+item.depositUsdtTrc20).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 2,
            })}`;
          }),
          sort: {
            key: "depositUsdtTrc20",
          },
        },
        {
          name: "Withdraw Usdt",
          key: "withdrawUsdt",
          render: sumMiddleware((item: any) => {
            return `$${(+item.withdrawUsdt).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 2,
            })}`;
          }),
          sort: {
            key: "withdrawUsdt",
          },
        },
        {
          name: "Withdraw Usdt Trc20",
          key: "withdrawUsdtTrc20",
          render: sumMiddleware((item: any) => {
            return `$${(+item.withdrawUsdtTrc20).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 2,
            })}`;
          }),
          sort: {
            key: "withdrawUsdtTrc20",
          },
        },
        {
          name: "User Blanace Usd",
          key: "userBalanceUsd",
          render: sumMiddleware((item: any) => {
            return `$${(+item.userBalanceUsd).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 2,
            })}`;
          }),
          sort: {
            key: "userBalanceUsd",
          },
        },
        {
          name: "User Blanace Usdt",
          key: "userBalanceUsdt",
          render: sumMiddleware((item: any) => {
            return `$${(+item.userBalanceUsdt).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 2,
            })}`;
          }),
          sort: {
            key: "userBalanceUsdt",
          },
        },
        {
          name: "User Blanace Usdt Trc20",
          key: "userBalanceUsdtTrc20",
          render: sumMiddleware((item: any) => {
            return `$${(+item.userBalanceUsdtTrc20).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 2,
            })}`;
          }),
          sort: {
            key: "userBalanceUsdtTrc20",
          },
        },
        {
          name: "Total order",
          key: "orderCount",
          render: sumMiddleware((item: any) => {
            return (+item.orderCount).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 2,
            });
          }),
          sort: {
            key: "sort-total-order",
          },
        },
        {
          name: "Safe Usdt",
          key: "safeUsdt",
          render: sumMiddleware((item: any) => {
            return `$${(+item.safeUsdt).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 2,
            })}`;
          }),
          sort: {
            key: "safeUsdt",
          },
        },
        {
          name: "Safe Usdt Trc20",
          key: "safeUsdtTrc20",
          render: sumMiddleware((item: any) => {
            return `$${(+item.safeUsdtTrc20).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 2,
            })}`;
          }),
          sort: {
            key: "safeUsdtTrc20",
          },
        },
      ].filter((v) => {
        if (modeOption === "hotWallet")
          return ["date", "hotWalletUsdt"].includes(v.key);
        if (modeOption === "deposit")
          return ["date", "depositUsdt"].includes(v.key);
        if (modeOption === "withdraw")
          return ["date", "withdrawUsdt"].includes(v.key);
        if (modeOption === "userBalance")
          return ["date", "userBalanceUsd", "userBalanceUsdt"].includes(v.key);
        if (modeOption === "safe") return ["date", "safeUsdt"].includes(v.key);
        return true;
      });

      const handleExportExcel = async () => {
        return new Promise((resolve) => {
          try {
            const reportData = ObjectUtils.getIn(reports, "data", []);
            const fileHead = structure.map((v) => v.name);
            const data = reportData.map((item: any) =>
              structure.map((column, index) => {
                if (index === 0)
                  return new Date(item["start"]).toLocaleString(getLocaleKey());
                return item[column.key];
              })
            );

            const dataExport = [
              fileHead,
              ...data,
              structure.map((column, index) => {
                if (index === 0) return "SUM";
                return reports.sumReports[column.key] || "";
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(dataExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(
              wb,
              `System report ${now
                .toLocaleDateString()
                .replace(/\//g, "-")} ${now
                .toLocaleTimeString()
                .replace(/:/g, "-")}.xlsx`,
              { type: "binary" }
            );

            resolve(
              CreateAlert({ type: "success", message: "Export data success." })
            );
          } catch (error) {
            resolve(CreateAlert({ type: "danger", message: error.message }));
          }
        });
      };

      return (
        <section className="box">
          <div className="title">System report</div>

          <SystemReportDetailPopup
            detail={systemReportDetail}
            onClose={() => setSystemReportDetail(null)}
          />

          <div className="SystemReport">
            <div className="SystemReport__Head">
              <div className="item">
                <div className="label">Range time</div>
                <InputDateTimeRange
                  onChange={(e) => setRangeTime(e)}
                  startTimeDefaultValue={rangeTime?.startTime}
                  endTimeDefaultValue={rangeTime?.endTime}
                />
              </div>
              <div className="item">
                <div className="label">Mode</div>
                <InputSelect
                  options={ModeOptions}
                  onChange={(e) => setModeOption(e)}
                  onTouched={() => false}
                  value={modeOption}
                  name=""
                />
              </div>

              <div className="item">
                <div className="label">Export</div>
                <Button
                  label="Excel"
                  buttonType="success"
                  disabled={
                    !reports || (reports.data && reports.data.length === 0)
                  }
                  onClick={handleExportExcel}
                />
              </div>
            </div>

            {(() => {
              if (!rangeTime)
                return (
                  <Message
                    type="info"
                    message="You have to select range time to export report"
                  />
                );
              if (!reports) return <Message type="loading" />;
              if (reports.error)
                return <Message type="error" {...reports.error} />;

              return (
                <Table
                  className="overload hideFooter"
                  structure={structure}
                  fetchData={async (params) => {
                    let data = [...reports.data];

                    if (params["sort-date"] === "increase")
                      data = data.sort((a: any, b: any) => a.start - b.start);
                    if (params["sort-date"] === "descrease")
                      data = data.sort((a: any, b: any) => b.start - a.start);

                    if (params["sort-won-volume"] === "increase")
                      data = data.sort(
                        (a: any, b: any) => a.wonVolume - b.wonVolume
                      );
                    if (params["sort-won-volume"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) => b.wonVolume - a.wonVolume
                      );

                    if (params["sort-lost-volume"] === "increase")
                      data = data.sort(
                        (a: any, b: any) => a.loseVolume - b.loseVolume
                      );
                    if (params["sort-lost-volume"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) => b.loseVolume - a.loseVolume
                      );

                    if (params["sort-total-order"] === "increase")
                      data = data.sort(
                        (a: any, b: any) => a.orderCount - b.orderCount
                      );
                    if (params["sort-total-order"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) => b.orderCount - a.orderCount
                      );

                    if (params["hotWalletUsdt"] === "increase")
                      data = data.sort(
                        (a: any, b: any) => a.hotWalletUsdt - b.hotWalletUsdt
                      );
                    if (params["hotWalletUsdt"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) => b.hotWalletUsdt - a.hotWalletUsdt
                      );

                    if (params["hotWalletUsdtTrc20"] === "increase")
                      data = data.sort(
                        (a: any, b: any) =>
                          a.hotWalletUsdtTrc20 - b.hotWalletUsdtTrc20
                      );
                    if (params["hotWalletUsdtTrc20"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) =>
                          b.hotWalletUsdtTrc20 - a.hotWalletUsdtTrc20
                      );

                    if (params["depositUsdt"] === "increase")
                      data = data.sort(
                        (a: any, b: any) => a.depositUsdt - b.depositUsdt
                      );
                    if (params["depositUsdt"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) => b.depositUsdt - a.depositUsdt
                      );

                    if (params["depositUsdtTrc20"] === "increase")
                      data = data.sort(
                        (a: any, b: any) =>
                          a.depositUsdtTrc20 - b.depositUsdtTrc20
                      );
                    if (params["depositUsdtTrc20"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) =>
                          b.depositUsdtTrc20 - a.depositUsdtTrc20
                      );

                    if (params["withdrawUsdt"] === "increase")
                      data = data.sort(
                        (a: any, b: any) => a.withdrawUsdt - b.withdrawUsdt
                      );
                    if (params["withdrawUsdt"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) => b.withdrawUsdt - a.withdrawUsdt
                      );

                    if (params["withdrawUsdtTrc20"] === "increase")
                      data = data.sort(
                        (a: any, b: any) =>
                          a.withdrawUsdtTrc20 - b.withdrawUsdtTrc20
                      );
                    if (params["withdrawUsdtTrc20"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) =>
                          b.withdrawUsdtTrc20 - a.withdrawUsdtTrc20
                      );

                    if (params["userBalanceUsd"] === "increase")
                      data = data.sort(
                        (a: any, b: any) => a.userBalanceUsd - b.userBalanceUsd
                      );
                    if (params["userBalanceUsd"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) => b.userBalanceUsd - a.userBalanceUsd
                      );

                    if (params["userBalanceUsdTrc20"] === "increase")
                      data = data.sort(
                        (a: any, b: any) =>
                          a.userBalanceUsdTrc20 - b.userBalanceUsdTrc20
                      );
                    if (params["userBalanceUsdTrc20"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) =>
                          b.userBalanceUsdTrc20 - a.userBalanceUsdTrc20
                      );

                    if (params["userBalanceUsdt"] === "increase")
                      data = data.sort(
                        (a: any, b: any) =>
                          a.userBalanceUsdt - b.userBalanceUsdt
                      );
                    if (params["userBalanceUsdt"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) =>
                          b.userBalanceUsdt - a.userBalanceUsdt
                      );

                    if (params["safeUsdt"] === "increase")
                      data = data.sort(
                        (a: any, b: any) => a.safeUsdt - b.safeUsdt
                      );
                    if (params["safeUsdt"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) => b.safeUsdt - a.safeUsdt
                      );

                    if (params["safeUsdtTrc20"] === "increase")
                      data = data.sort(
                        (a: any, b: any) => a.safeUsdtTrc20 - b.safeUsdtTrc20
                      );
                    if (params["safeUsdtTrc20"] === "descrease")
                      data = data.sort(
                        (a: any, b: any) => b.safeUsdtTrc20 - a.safeUsdtTrc20
                      );

                    return {
                      data: [{ isSum: true }, ...data],
                      count: data.length,
                    };
                  }}
                />
              );
            })()}
          </div>
        </section>
      );
    },
    () => true
  )
);

export const SystemReportDetailPopup: FC<{
  detail: any;
  onClose: () => void;
}> = (props) => {
  if (!props.detail) return null;

  const item = props.detail;

  const data = [
    {
      label: "Start",
      value: `${new Date(item.start).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "End",
      value: `${new Date(item.end).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "Modified",
      value: `${new Date(item.modified).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "Total won/lose volume",
      value: `${(+item.wonVolume).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
      })}/${(+item.lostVolume).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
      })}`,
    },
    {
      label: "Hot Wallet",
      value: (
        <span>
          USDT:{" "}
          {(+item.hotWalletUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
          <br />
          USDT-TRC20:{" "}
          {(+item.hotWalletUsdtTrc20).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      label: "Deposit",
      value: (
        <span>
          USDT:{" "}
          {(+item.depositUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
          <br />
          USDT-TRC20:{" "}
          {(+item.depositUsdtTrc20).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      label: "Withdraw",
      value: (
        <span>
          USDT:{" "}
          {(+item.withdrawUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
          <br />
          USDT-TRC20:{" "}
          {(+item.withdrawUsdtTrc20).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      label: "User 's Balance",
      value: (
        <span>
          USD:{" "}
          {(+item.userBalanceUsd).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}{" "}
          <br />
          USDT:{" "}
          {(+item.userBalanceUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
           <br />
          USDT-TRC20:{" "}
          {(+item.userBalanceUsdTrc20).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      label: "Safe",
      value: (
        <span>
          USDT:{" "}
          {(+item.safeUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
          USDT-TRC20:{" "}
          {(+item.safeUsdtTrc20).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
  ];

  return (
    <div
      className="SystemReport__DetailPopup"
      id="SystemReport__DetailPopup"
      onClick={(e: any) =>
        e.target.id === "SystemReport__DetailPopup" ? props.onClose() : null
      }
    >
      <div className="box">
        <div className="boxTitle">
          <span>Detail Report</span>
          <div className="btnClose" onClick={() => props.onClose()}>
            <Icon.Close />
          </div>
        </div>
        <div className="content">
          <table>
            <tbody>
              {data.map((row, key) => (
                <tr key={key}>
                  <td>{row.label}</td>
                  <td>{row.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
