import React, { FC, useEffect } from 'react'

function selects(obj: any, keys: any[]) {
    return keys.reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
    }, {})
}

export function flatTreeData(input: any[]) {
    try {
        let output = []

        const getChildren = (obj: any) => {

            if (obj && obj.children && obj.children.length > 0) {
                const { children } = obj;

                children.map((item: any) => {
                    output.push({ ...item })
                    getChildren(item);
                    return item;
                })
            }
        }

        output.push(input);
        getChildren(input);

        output.map((item: any) => {
            delete item.children;
            return item;
        })

        return output;
    } catch (error) {
        return []
    }
}

export const TreeView: FC<{ affiliate: any[] }> = (props) => {
    useEffect(() => {
        // @ts-ignore
        const OrgChart: any = window ? window.OrgChart : undefined;
 
        if (OrgChart) {
            const { affiliate } = props;

            const data = affiliate.map(((item: any) => {
                let email = item.email;
                let rank = '';
                let img = `/assets/images/rank/_${item.rank}.png`;
                // let fullname = item.firstName.toUpperCase() + ' ' + item.lastName.toUpperCase();
                let fullname = item.firstName + ' ' + item.lastName;
 
                return ({
                    ...selects(item, ['email', 'parentId', 'level', 'revenue', 'numberOfActiveF1']),
                    id: item.userId,
                    pid: item.presenterId,
                    name: fullname,
                    rank,
                    img,
                    email
                })
            }))

            new OrgChart(document.getElementById("tree"), {
                template: 'diva',
                enableSearch: true,
                nodeBinding: {
                    field_0: "name",
                    field_1: "email",
                    field_2: "rank",
                    img_0: "img"
                },
                nodes: data,
            });
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="TreeView">
            <div id="tree" />
        </div>
    )
}