import React, { FC, useState } from "react";
import * as XLSX from "xlsx";

import { AdminService } from "../../../services/admin";
import { useSelector } from "../../../store";
import { Table, Icon, Button } from "../../../modules";
import { withFranchiseWraper } from "../wraper";
import { getLocaleKey } from "../../../languages";
import { TableFilterInputText } from "../../../components/table-filter-inputs";

export const PageFranchiseTable = withFranchiseWraper(() => {
  const user = useSelector((state) => state.user);
  const [currentData, setCurrentData] = useState(null as any);

  return (
    <div className="PageFranchiseTable">
      <Button
        disabled={!currentData}
        label="Export to Excel"
        buttonType="success"
        className="mb15"
        onClick={() => {
          const data = [
            [
              "User",
              "Won Volume",
              "Lose Volume",
              "Deposit",
              "Withdraw",
              "Balance",
              "Rank Commission",
              "Direct Commission",
              "Cashback",
              "Agency Count",
            ],
            ...currentData.full.map((item: any) => {
              let row: any[] = [];
              row.push(item.email);
              row.push(
                `$${(+item.wonVolume).toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 2,
                })}`
              );
              row.push(
                item.lostVolume
                  ? `-$${(+item.lostVolume).toLocaleString(getLocaleKey(), {
                      maximumFractionDigits: 2,
                    })}`
                  : 0
              );
              row.push(
                `USDT-TRC20: ${(+item.depositUsdtTrc20).toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 2,
                })} USDT: ${(+item.depositUsdt).toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 2,
                })}`
              );
              row.push(
                `USDT-TRC20: ${(+item.withdrawUsdtTrc20).toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 2,
                })} USDT: ${(+item.withdrawUsdt).toLocaleString(
                  getLocaleKey(),
                  { maximumFractionDigits: 2 }
                )}`
              );
              row.push(
                `USD: ${(+item.balanceUsd).toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 2,
                })} USDT: ${(+item.balanceUsdt).toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 2,
                })} USDT: ${(+item.balanceUsdtTrc20).toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 2,
                })}`
              );
              row.push(item.rankCommission);
              row.push(item.directCommission);
              row.push(item.cashback);
              row.push(item.agencyCount);

              return row;
            }),
          ];

          const ws = XLSX.utils.aoa_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(
            wb,
            `Franchise ${now
              .toLocaleDateString()
              .replace(/\//g, "-")} ${now
              .toLocaleTimeString()
              .replace(/:/g, "-")}.xlsx`,
            { type: "binary" }
          );
        }}
      />

      <Table
        filters={[
          {
            name: "User",
            key: "q",
            input: TableFilterInputText,
          },
        ]}
        structure={[
          {
            name: "User",
            key: "q",
            render: (item) => {
              let fullname = item.firstName + " " + item.lastName;
              return (
                <div>
                  {fullname} <br />
                  {item.email} <br />
                </div>
              );
            },
          },
          {
            name: "Won volume",
            key: "wonVolume",
            className: "textSuccess",
            render: (item) => {
              return `$${(+item.wonVolume).toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 2,
              })}`;
            },
            sort: {},
          },
          {
            name: "Lose volume",
            key: "lostVolume",
            className: "textDanger",
            render: (item) => {
              const value = +item.lostVolume;
              return value
                ? `-$${(+item.lostVolume).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                  })}`
                : 0;
            },
            sort: {},
          },
          {
            name: "Deposit",
            render: (item) => {
              return (
                <span>
                  USDT-TRC20:{" "}
                  {(+item.depositUsdtTrc20).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                  })}{" "}
                  <br />
                  USDT:{" "}
                  {(+item.depositUsdt).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                  })}
                </span>
              );
            },
          },
          {
            name: "Withdraw",
            render: (item) => {
              return (
                <span>
                  USDT-TRC20:{" "}
                  {(+item.withdrawUsdtTrc20).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                  })}{" "}
                  <br />
                  USDT:{" "}
                  {(+item.withdrawUsdt).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                  })}
                </span>
              );
            },
          },
          {
            name: "Balance",
            render: (item) => {
              return (
                <span>
                  USD:{" "}
                  {(+item.balanceUsd).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                  })}{" "}
                  <br />
                  USDT:{" "}
                  {(+item.balanceUsdt).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                  })}
                  <br />
                  USDT-TRC20:{" "}
                  {(+item.balanceUsdtTrc20).toLocaleString(getLocaleKey(), {
                    maximumFractionDigits: 2,
                  })}
                </span>
              );
            },
          },
          {
            name: "Rank Commission",
            key: "rankCommission",
            render: (item) => {
              return `${(+item.rankCommission).toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 2,
              })}`;
            },
            sort: {},
          },
          {
            name: "Direct Commission",
            key: "directCommission",
            render: (item) => {
              return `${(+item.directCommission).toLocaleString(
                getLocaleKey(),
                { maximumFractionDigits: 2 }
              )}`;
            },
            sort: {},
          },
          {
            name: "Cashback",
            key: "cashBack",
            render: (item) => {
              return `${(+item.cashBack).toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 2,
              })}`;
            },
            sort: {},
          },
          {
            name: "Agency Count",
            key: "agencyCount",
            render: (item) => {
              return `${(+item.agencyCount).toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 2,
              })}`;
            },
            sort: {},
          },
        ]}
        fetchData={async (params) =>
          AdminService.getFranchiseUserReport(21, user.userId, params).then(
            (res) => {
              setCurrentData(res);
              return res;
            }
          )
        }
      />
    </div>
  );
});

export const SystemReportDetailPopup: FC<{
  detail: any;
  onClose: () => void;
}> = (props) => {
  if (!props.detail) return null;

  const item = props.detail;

  const data = [
    {
      label: "Start",
      value: `${new Date(item.start).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "End",
      value: `${new Date(item.end).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "Modified",
      value: `${new Date(item.modified).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "Total won/lose volume",
      value: `${(+item.wonVolume).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
      })}/${(+item.lostVolume).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
      })}`,
    },
    {
      label: "Hot Wallet",
      value: (
        <span>
          USDT-TRC20:{" "}
          {(+item.hotWalletUsdtTrc20).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}{" "}
          <br />
          USDT:{" "}
          {(+item.hotWalletUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      label: "Deposit",
      value: (
        <span>
          USDT-TRC20:{" "}
          {(+item.depositUsdtTrc20).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}{" "}
          <br />
          USDT:{" "}
          {(+item.depositUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      label: "Withdraw",
      value: (
        <span>
          USDT-TRC20:{" "}
          {(+item.withdrawUsdtTrc20).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}{" "}
          <br />
          USDT:{" "}
          {(+item.withdrawUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      label: "User 's Balance",
      value: (
        <span>
          USD:{" "}
          {(+item.userBalanceUsd).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}{" "}
          <br />
          USDT:{" "}
          {(+item.userBalanceUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
          <br />
          USDT-TRC20:{" "}
          {(+item.userBalanceUsdtTrc20).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      label: "Safe",
      value: (
        <span>
          USDT-TRC20:{" "}
          {(+item.safeUsdtTrc20).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}{" "}
          <br />
          USDT:{" "}
          {(+item.safeUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      label: "Login Count",
      value: item.loginCount,
    },
    {
      label: "Direct Commission",
      value: item.directCommission,
    },
    {
      label: "CashBack",
      value: (+item.cashBack).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
      }),
    },
    {
      label: "New Package",
      value: item.newPackage,
    },
    {
      label: "Rank Commission",
      value: item.rankCommission,
    },
  ];

  return (
    <div
      className="SystemReport__DetailPopup"
      id="SystemReport__DetailPopup"
      onClick={(e: any) =>
        e.target.id === "SystemReport__DetailPopup" ? props.onClose() : null
      }
    >
      <div className="box">
        <div className="boxTitle">
          <span>Detail Report</span>
          <div className="btnClose" onClick={() => props.onClose()}>
            <Icon.Close />
          </div>
        </div>
        <div className="content">
          <table>
            <tbody>
              {data.map((row, key) => (
                <tr key={key}>
                  <td>{row.label}</td>
                  <td>{row.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
