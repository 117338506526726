import React, { useState, useEffect, memo } from "react";
import * as XLSX from "xlsx";

import {
    TableScroll,
    ITableStructureItem,
    CreateAlert,
    Button,
    Icon,
} from "../../../modules";
import { AdminService } from "../../../services/admin";
import { Link } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
import { withUserWraper } from "../wraper";
import { TableFilterInputText } from "../../../components/table-filter-inputs";
import { translate } from "../../../languages";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { PopupWraper } from "../../../components/popup";
import "./UserList.scss";
import { Ban } from "./ban";
import { EditUser } from "./edit";
import { useSelector } from "../../../store";

export const UserList = withUserWraper(
    memo(() => {
        const [params, setParams] = useState([] as any[]);
        const [editData, setEditData] = useState(null);
        const [freezeDataId, setFreezeDataId] = useState<number | null>(null);
        const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());
        const user = useSelector((state) => state.user);

        const structure: ITableStructureItem[] = [
            {
                name: "Email",
                key: "email",
                render: (item) => {
                    return (
                        <Link to={Routes.userDetail.renderPath(item.userId)}>
                            {item.email}
                        </Link>
                    );
                },
            },
            {
                name: "Username",
                key: "username",
            },
            {
                name: "First Name",
                key: "firstName",
            },
            {
                name: "Last Name",
                key: "lastName",
            },
            {
                name: "Phone",
                key: "phoneNumber",
            },
            {
                name: translate("status"),
                key: "status",
                sort: {
                    descreaseValue: "desc",
                    increaseValue: "",
                },
            },
            {
                name: translate("actions"),
                key: "actions",
                render: (item) => {
                    return (
                        <div className="btnGroup">
                            <span
                                className="btn btn__edit"
                                onClick={() => setEditData({ ...item })}
                            >
                                <Icon.Edit />
                            </span>
                            <span
                                className="btn btn__ban"
                                onClick={() => setFreezeDataId(item.userId)}
                            >
                                <Icon.Error />
                            </span>
                        </div>
                    );
                },
            },
        ];

        const handleExportExcel = async () => {
            return new Promise(async (resolve) => {
                try {
                    const response = await AdminService.getListUser({
                        ...params,
                        page: 1,
                        numberOfItemsPerPage: 1000,
                    });

                    const data = response.data;

                    const fileHead = structure.map((v) => v.name);
                    const dataExport = [
                        fileHead,
                        ...data.map((item: any) =>
                            structure.map((column, index) => {
                                if (!column.key) return "";
                                return item[column.key];
                            })
                        ),
                    ];

                    const ws = XLSX.utils.aoa_to_sheet(dataExport);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                    const now = new Date();
                    XLSX.writeFile(
                        wb,
                        `User List ${now
                            .toLocaleDateString()
                            .replace(
                                /\//g,
                                "-"
                            )} ${now
                            .toLocaleTimeString()
                            .replace(/:/g, "-")}.xlsx`,
                        { type: "binary" }
                    );

                    resolve(
                        CreateAlert({
                            type: "success",
                            message: "Export data success.",
                        })
                    );
                } catch (error) {
                    resolve(
                        CreateAlert({ type: "danger", message: error.message })
                    );
                }
            });
        };

        return (
          <div className="UserList">
            <Button
              className="mb20"
              label="Export excel"
              buttonType="success"
              onClick={handleExportExcel}
            />
              <TableScroll
                isScrollInside
                filters={[
                  {
                    name: "Email",
                    key: "email",
                    input: TableFilterInputText,
                  },
                  {
                    name: "Name",
                    key: "name",
                    input: TableFilterInputText,
                  },
                  // {
                  //     name: translate("type"),
                  //     key: "status",
                  //     input: (s) => (
                  //         <TableFilterInputSelect
                  //             {...s}
                  //             options={AdminService.getListUserStatus()}
                  //         />
                  //     ),
                  // },
                ]}
                structure={structure}
                fetchData={async (params) => {
                  setParams(params);
                  return AdminService.getListUser({
                    page: params.pageNumber,
                    numberOfItemsPerPage: params.limit,
                    ...params,
                  });
                }}
                forceUpdateTable={forceUpdateTable}
              />
            {editData && (
              <PopupWraper
                center
                title="Edit user"
                onClose={() => setEditData(null)}
              >
                <EditUser
                  data={editData}
                  onFinishEdit={() => {
                    setForceUpdateTable(Math.random);
                    setEditData(null);
                  }}
                />
              </PopupWraper>
            )}
            {freezeDataId && (
              <PopupWraper
                center
                title="Freeze user"
                onClose={() => setFreezeDataId(null)}
              >
                <Ban
                  userId={freezeDataId}
                  // onClick={async () => {
                  //     try {
                  //         // userId
                  //         console.log("here");
                  //         if (user.userId === userId)
                  //             throw new Error(
                  //                 "Can't Freeze Yourself"
                  //             );

                  //         if (freezeDataId) {
                  //             const res = await AdminService.freezeUsers(
                  //                 [freezeData.userId]
                  //             );
                  //             const { success } = res;
                  //             if (success) {
                  //                 CreateAlert({
                  //                     type: "success",
                  //                     message: "Freeze data success.",
                  //                 });
                  //                 setFreezeData(null);
                  //             }
                  //         }
                  //     } catch (error) {
                  //         CreateAlert({
                  //             type: "danger",
                  //             message:
                  //                 error?.message ||
                  //                 "Freeze data fail.",
                  //         });
                  //     }
                  // }}
                />
              </PopupWraper>
            )}
          </div>
        );
    })
);
